import React from 'react';
import styled from 'styled-components'

const BoxStyled = styled.div`
    font-size: 16px;
    padding: 20px;
    line-height: 22px;
    background-color: var(--color-status-error);
    color: #fff;
    
`


class Error extends React.Component {
    render() {
        return (
            <BoxStyled>
                {this.props.children}
            </BoxStyled>
      );
    }
}

export default Error