import React from 'react';
import styled from 'styled-components'


const Wrapper = styled.div`
    padding: 5px 0 10px;
`
const FooterStyled = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 860px;
    margin: auto;
    
    a {
        padding: 10 20px; 
        margin: 0 10px;

        display: block;
        text-decoration: none;
       
        &:hover,
        &:focus {
            text-decoration: underline;
            outline: none;
        }
        
        color: blue;
        &.is-active {
            color: green;
        }
    }
`


function Footer(props) {

    return (
    <Wrapper>
        <FooterStyled>
        </FooterStyled>
        
    </Wrapper>
    );

}

export default Footer;
