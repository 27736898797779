import React from 'react';
import styled from 'styled-components';
import Meta from "../base/Meta"
import formatDate from "../base/formatDate"
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
background: var(--color-background-inverse);
    padding: 10px 10px;
    border-radius: 3px;
    color: #fff;
    transition: linear 200ms;

    &:hover {
        background: var(--color-background-inverse-hover);
    }
`

const H2 = styled.h2`
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    margin: 0;
    color: #fff;
`

class ChapterButton extends React.Component {
    
    render() {     
        const lastModified = formatDate(this.props.lastModified)
        const linkTo = "/book/"+this.props.bookKey+"/"+this.props.chapterKey
        return (
            <NavLink end to={linkTo}>
                <Wrapper>        
                    <H2>{this.props.title}</H2>
                    <Meta>letzte Aktualisierung: {lastModified}</Meta>
                </Wrapper>     
            </NavLink>
        )
    }
}

export default ChapterButton