import React from 'react';
import styled from 'styled-components'

const Row = styled.div`
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    &.-alignStart {
        align-items: center;
    }

    &.-noMargin {
        margin-top: 0;
    }
`

class ButtonRow extends React.Component {

    render() {

        let classString = []
        

        if (this.props.align === "start") {
            classString.push("-alignStart")
        }

        if (this.props.margin === "no") {
            classString.push("-noMargin")
        }

        return (
            <Row className={classString}>
                {this.props.children}
            </Row>
      );
    }
}

export default ButtonRow