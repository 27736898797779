import React from 'react'
import request from '../RequestToHost'
import downloadFile from '../downloadFile.js'

import styled from 'styled-components'
import Image from "../base/Image"
import Error from "../base/Error"
import MetaFlex from './MetaFlex'
import Textarea from '../base/Textarea'
import Button from '../base/Button'

const Wrapper = styled.div`
  margin-bottom: 30px;
`

const Input = styled.input`
  display: block;
  margin-bottom: 10px;
`

const ImageFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 500px) {
    &.-narrow {
      flex-direction: row;
      
      .imageCol,
      .asideCol {
        width: 240px;
        flex-grow: 1;
      }
    }
  }
`

class BlockImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      savingImage: false,
      value: "",
      fileResult: ""
    }

    this.handleDel = this.handleDel.bind(this)
    this.handleImageInput = this.handleImageInput.bind(this)
    this.handleDescriptionKeyDown = this.handleDescriptionKeyDown.bind(this)
    this.handleDescriptionBlur = this.handleDescriptionBlur.bind(this)
    this.handleModeToggle = this.handleModeToggle.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
  }

  handleDel () {
      this?.props?.onDelete({id:this.props.id})
  }

  handleImageInput(e) {
    this.setState({value: e.target.value});
    const file = e.target.files[0];
    this.setState({fileTypeError: !(file.type === "image/jpeg" || file.type === "image/png"), savingImage: true})

    const formData = new FormData();
		formData.append('file', file);

    request({method: "post", route: "/blockImageFile", data: formData, type: "multipart"})
    .then((response) => {
      // console.log("BlockImage postImage response", response, "path", response.data.path)
      this.setState({savingImage: false})

      let content = this.props?.block?.content || {}
      content.path = response.data.path
      this.saveContent(content)

    })
  }

  handleDescriptionKeyDown (e) {
    // save on ctrl+s
    if (e.key === "s" && e.ctrlKey) {
      e.preventDefault()
      e.stopPropagation()
      let content = this.props?.block?.content
      content.description = e.target.value
      this.saveContent(content)
    }
  }

  handleDescriptionBlur (e) {
    console.log("BlockH3 handleBlur e", e)
    if (this.props?.block?.content !== e.target.value) {
      let content = this.props?.block?.content
      content.description = e.target.value
      this.saveContent(content)
    }
  }

  handleModeToggle() {
    let newMode = !this.props.block?.content?.modeWide
    console.log("set modeWide to",newMode )
    let content = this.props?.block?.content
    content.modeWide = newMode
    this.saveContent(content)
  }

  async handleDownload() {
    console.log("handleDownload", this.props?.block)
    await downloadFile({route: this.props?.block?.content?.path, fileName: undefined})
  }

  saveContent (content) {
    this?.props?.onSaveContent(content)  
  }

  render () {
    console.log("render blockImage", this.props?.block)
    let fileTypeError = this.state.fileTypeError ? <Error>Falscher Dateityp. Es sind nur png und jpeg erlaubt</Error> : ""
    let defaultContent = ""
    if (this.props?.block?.content?.description) {
      defaultContent = this.props.block.content.description
    }

    const modeWideClass = this.props.block?.content?.modeWide ? "-wide" : "-narrow"
    const modeWideButtonLabel = this.props.block?.content?.modeWide ? "klein" : "groß"

    let conditional = ""
    if (this.props.block?.content?.path) {
      conditional = <ImageFlex className={modeWideClass}>
        <div className="imageCol">
          <Image src={this.props.block?.content?.path}></Image>
        </div>
        <div className="asideCol">
          <Textarea placeholder="Description" defaultContent={defaultContent} onBlur={this.handleDescriptionBlur} onKeyDown={this.handleDescriptionKeyDown}></Textarea>
          <Button action={this.handleModeToggle}>{modeWideButtonLabel}</Button> <Button action={this.handleDownload}>download</Button>
        </div>
      </ImageFlex>
    } else {
      conditional = <div>
        <Input 
          type="file" 
          value={this.state.value} 
          onInput={this.handleImageInput}
          accept="image/png, image/jpeg">
        </Input>
      </div>
    }

    let isSaving = this.props.saving || this.state.savingImage

    return (
    <Wrapper>
      <MetaFlex id={this.props.id} lastModified={this.props?.block?.lastModified} onDelete={this.handleDel} saving={isSaving}></MetaFlex>
      {conditional}
      {fileTypeError}
    </Wrapper>)
  }
}

export default BlockImage