import React from 'react';
import request from '../RequestToHost';
import styled from 'styled-components';
import ButtonRow from '../base/ButtonRow';
import Button from '../base/Button';

const Error = styled.div`
    color: red;
    padding: 20px 0;
`

class BooksAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            creating: false
        };

        this.handleNew = this.handleNew.bind(this)
    }
               
    handleNew () {
        this.setState({creating: true})

        request({method: "post", route: "/books/"})
            .then((response) => { 
                if (response && response.error) {
                    this.setState({creating: false, error: response.error.toString()})
                    console.error("handleNew post error", response.error)
                }
                if (response && response.data) {
                    this.setState({creating: false})                                        
                    this.props.navigate("/book/"+response.data.bookKey)
                }
            }).catch((err) => {
                console.error("catch", err)
            })
    }

    render() {
        let errorTag = ""
        if (this.state.error) {
            errorTag = <Error>Error on post /books/: {this.state.error}</Error>
        }

        const creating = this.state.creating ? <div>creating</div> : ""
    
        return (
            <div>
                {creating}
                {errorTag}              
                <ButtonRow>
                    <Button action={this.handleNew}>neues Buch</Button>
                </ButtonRow>
            </div>
        )
    }
}

export default BooksAdd