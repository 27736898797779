import React from 'react';
import styled from 'styled-components';

import Button from '../base/Button';
import ButtonRow from '../base/ButtonRow';
import request from '../RequestToHost';

const Wrapper = styled.div`
  margin-top: 20px;
`

class BlockNew extends React.Component {

  constructor(props) {
    super(props);

    this.addBlockH2 = this.addBlockH2.bind(this)
    this.addBlockH3 = this.addBlockH3.bind(this)
    this.addBlockText = this.addBlockText.bind(this)
    this.addImageText = this.addImageText.bind(this)
    this.addBlockQuote = this.addBlockQuote.bind(this)
    this.addBlockFile = this.addBlockFile.bind(this)
  }

  addBlockH2() {
    this.createBlock("h2")
  }
  addBlockH3() {
    this.createBlock("h3")
  }
  addBlockText() {
    this.createBlock("text")
  }
  addImageText() {
    this.createBlock("image")
  }
  addBlockFile() {
    this.createBlock("file")
  }
  addBlockQuote() {
    this.createBlock("quote")
  }

  createBlock (type) {
    request({method: "post", route: "/blocks/", data: {type}})
      .then((response) => {
        console.log("handleAddBlock post response", response)
        if (response.data && this.props.addBlock) {
          this.props.addBlock(response.data)
        }
      })
  }

  render () {
    return (<Wrapper>
      <ButtonRow>
        <Button layout="neutral" small action={this.addBlockH2}>H2</Button>
        <Button layout="neutral" small action={this.addBlockH3}>H3</Button>
        <Button layout="neutral" small action={this.addBlockText}>Text</Button>
        <Button layout="neutral" small action={this.addImageText}>Image</Button>
        <Button layout="neutral" small action={this.addBlockFile}>File</Button>
        <Button layout="neutral" small action={this.addBlockQuote}>Quote</Button>
      </ButtonRow>
    </Wrapper>)
  }
}

export default BlockNew