import React from "react"
import Box from "../base/Box"
import Header from "../base/Header"
import request from "../RequestToHost"
import styled from "styled-components"
import BookButton from "./BooksButton"
import H1 from "../base/H1"
import BooksAdd from "./BooksAdd"

const Error = styled.div`
    color: red;
    padding: 20px 0;
`

const Flex = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px; 
    margin-top: 20px;

    & > * {
        flex-grow: 1;
        flex-shrink: 1;
        flex-base: 0px;
        width: 200px;
    }
`

class Books extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false, 
            error: false,
            books: []
        };
    }

    componentDidMount() {
        this.requestBooks()
    }
               
    requestBooks () {
        request({route: "/books/"})
        .then(({data, error}) => {
            // console.log("fetch then books response ", response)
            if (error) {
                this.setState({ error, loading: false })    
            }
            if (data) {
                this.setState({books: data, loading: false})
            }
        })
        .catch((error) => {
            this.setState({ error, loading: false })
        })
    }

    render() {
        let errorTag = ""
        if (this.state.error?.message) {
            errorTag = <Error>Error on /books/: {JSON.stringify(this.state.error?.message)}</Error>
        }

        let bookArray =[]
        let bookArrayTag = []
        if (this.state.books) {
            let keys = Object.keys(this.state.books)
            for (let key of keys) {
                bookArray.push(this.state.books[key].abstract)
            }

            bookArray.sort((a, b) => {
                return b.lastModified - a.lastModified;
            })

            for (let abstract of bookArray) {
                bookArrayTag.push(<BookButton key={abstract.key} abstract={abstract}></BookButton>)
            }
        }

        const loading = this.state.loading ? <div>loading</div> : ""
        

        return (
            <Box>
              <Header></Header>
              <H1>Notus Bücher</H1>
              <BooksAdd navigate={this.props.navigate}></BooksAdd>
              {errorTag}
              <Flex>{bookArrayTag}</Flex>
              {loading}
            </Box>
        )
    }
}

export default Books