import axios from "axios";
import { getAuthCode, getHost } from "./routeLogin/auth";

export default async function request(conf) {
  // console.log("RequestTohost conf", conf)
  const method = conf?.method || "get"
  if (!conf?.route) {
    console.warn("Request got called without route in conf", conf)
  }  
  try {
    let headers = {
      Authorization: getAuthCode(),
    }
    if (conf.type === "multipart") {
      headers["Content-Type"] = "multipart/form-data" 
    }

    let response = await axios({
      url: getHost() + conf.route,
      method: method,
      data: conf.data,
      headers
    });

    return response;

  } catch (error) {
    return { error };
  }
}