import React from 'react'
import styled from 'styled-components';
import Textarea from '../base/Textarea'
import MetaFlex from './MetaFlex'

const QuoteWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-height: 200px;
`

const Inner = styled.div`
  flex-grow: 3;
  position: relative;
  top: 16px;
`

const MarkBefore = styled.div`
  font-size: 100px;
  flex-grow: 1;
  text-align: right;
  color: #888;
  align-self: flex-end;
  font-style: italic;

  svg {
    width: 40px;
    height: 40px;
    transform: rotate(0.5turn);
  }
  path {
    fill: #888;
  }
`
const MarkAfter = styled.div`
  font-size: 100px;
  flex-grow: 1;
  color: #888;
  align-self: flex-start;
  font-style: italic;

  svg {
    width: 40px;
    height: 40px;
  }
  path {
    fill: #888;
  }
`

class BlockQuote extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleDel = this.handleDel.bind(this)

    this.refText = React.createRef();
    this.refAuthor = React.createRef();
  }

  handleDel () {
    this?.props?.onDelete({id:this.props.id})
  }

  handleKeyDown (e) {
    // save on ctrl+s
    if (e.key === "s" && e.ctrlKey) {
      console.log("blockQuote should save")
      e.preventDefault()
      e.stopPropagation()
      this.saveContent()
    }

    if (e.key === "Backspace" && e.target.value === "") {
      // delete on backspace on an empty textarea
      this?.props?.onDelete({id:this.props.id})
    }
  }

  handleBlur (e) {
    console.log("BlockH3 handleBlur e", e)
    this.saveContent()
  }

  
  saveContent () {

    let content = this.props?.block?.content || {}
    let newText = this.refText?.current?.refTextarea?.current?.value
    let newAuthor = this.refAuthor?.current?.refTextarea?.current?.value

    if (content.text !== newText || content.author !== newAuthor) {
      content.text = newText
      content.author = newAuthor
      console.log("saveContent content", content)
      this?.props?.onSaveContent(content)  
    }
  }

  render () {
   
    let defaultContentText = this.props?.block?.content?.text || ""
    let defaultContentAuthor = this.props?.block?.content?.author || ""

    return (
    <div>
      <MetaFlex id={this.props.id} lastModified={this.props?.block?.lastModified} onDelete={this.handleDel}  saving={this.props.saving}></MetaFlex>
      <QuoteWrapper>
        <MarkBefore>
        <svg viewBox="0 0 349.078 349.078">
	<path d="M150.299,26.634v58.25c0,7.9-6.404,14.301-14.304,14.301c-28.186,0-43.518,28.909-45.643,85.966h45.643
		c7.9,0,14.304,6.407,14.304,14.304v122.992c0,7.896-6.404,14.298-14.304,14.298H14.301C6.398,336.745,0,330.338,0,322.447V199.455
		c0-27.352,2.754-52.452,8.183-74.611c5.568-22.721,14.115-42.587,25.396-59.048c11.608-16.917,26.128-30.192,43.16-39.44
		C93.886,17.052,113.826,12.333,136,12.333C143.895,12.333,150.299,18.734,150.299,26.634z M334.773,99.186
		c7.896,0,14.305-6.407,14.305-14.301v-58.25c0-7.9-6.408-14.301-14.305-14.301c-22.165,0-42.108,4.72-59.249,14.023
		c-17.035,9.248-31.563,22.523-43.173,39.44c-11.277,16.461-19.824,36.328-25.393,59.054c-5.426,22.166-8.18,47.266-8.18,74.605
		v122.992c0,7.896,6.406,14.298,14.304,14.298h121.69c7.896,0,14.299-6.407,14.299-14.298V199.455
		c0-7.896-6.402-14.304-14.299-14.304h-44.992C291.873,128.095,306.981,99.186,334.773,99.186z"/>
</svg>

        </MarkBefore>
        <Inner>
        <Textarea placeholder="Zitat" ref={this.refText} defaultContent={defaultContentText} onBlur={this.handleBlur} onKeyDown={this.handleKeyDown} type="quoteText"></Textarea>
        <Textarea placeholder="Autor" ref={this.refAuthor} defaultContent={defaultContentAuthor} onBlur={this.handleBlur} onKeyDown={this.handleKeyDown} type="quoteAuthor"></Textarea>
        </Inner>
        <MarkAfter><svg viewBox="0 0 349.078 349.078">
	<path d="M150.299,26.634v58.25c0,7.9-6.404,14.301-14.304,14.301c-28.186,0-43.518,28.909-45.643,85.966h45.643
		c7.9,0,14.304,6.407,14.304,14.304v122.992c0,7.896-6.404,14.298-14.304,14.298H14.301C6.398,336.745,0,330.338,0,322.447V199.455
		c0-27.352,2.754-52.452,8.183-74.611c5.568-22.721,14.115-42.587,25.396-59.048c11.608-16.917,26.128-30.192,43.16-39.44
		C93.886,17.052,113.826,12.333,136,12.333C143.895,12.333,150.299,18.734,150.299,26.634z M334.773,99.186
		c7.896,0,14.305-6.407,14.305-14.301v-58.25c0-7.9-6.408-14.301-14.305-14.301c-22.165,0-42.108,4.72-59.249,14.023
		c-17.035,9.248-31.563,22.523-43.173,39.44c-11.277,16.461-19.824,36.328-25.393,59.054c-5.426,22.166-8.18,47.266-8.18,74.605
		v122.992c0,7.896,6.406,14.298,14.304,14.298h121.69c7.896,0,14.299-6.407,14.299-14.298V199.455
		c0-7.896-6.402-14.304-14.299-14.304h-44.992C291.873,128.095,306.981,99.186,334.773,99.186z"/>
</svg>
</MarkAfter>
      </QuoteWrapper>
    </div>)
  }
}

export default BlockQuote