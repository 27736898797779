import React from 'react';
import {hasCredentials} from "./auth"
import Profile from "./Profile"
import LoginForm from "./LoginForm"



class PageLogin extends React.Component {
    constructor(props) {
        super(props);
        const isLoggedIn = hasCredentials()
        this.state = { isLoggedIn: isLoggedIn};
        this.loginCb = this.loginCb.bind(this);        
    }

    loginCb () {
        this.setState({isLoggedIn: true})
    }

    render() {
        return (
            <div>
                <Profile isLoggedIn={this.state.isLoggedIn}/> 

                {(!this.state.isLoggedIn) ? (
                     <LoginForm loginCb={this.loginCb} />
                ) : (
                   ""
                )}                
            </div>
        )
    }
}

export default PageLogin