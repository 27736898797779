let charset = "abcdefghijklmnopqrstuvwxyz1234567890";

function getRndChar() {
    return charset[Math.floor(Math.random()*charset.length)]
}

function randomId(length) {

  length = length || 6

  let ret = ""
  let runs = 0
  while(runs < length) {
    ret += getRndChar()
    runs++
  }
  
  return ret;
}

export default randomId