import React from 'react';
import styled from 'styled-components'
import requestImage from '../RequestImage'

const ImageElement = styled.img`
    max-width: 100%;
    height: auto;   
`

class Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          dataUrl: ""
        }
    
        this.loadImage = this.loadImage.bind(this)
      }

    componentDidMount() {
        this.loadImage()
    }
    componentDidUpdate(prevProps) {
        if (this.props.src !== prevProps.src) {
            this.loadImage()
        }
    }

    async loadImage () {

        if (this.props.src) {

            // better save it to session storage.
            // would be nice to use browsercaching. but ... yeah... no images with access token
            // you could implement it in a service worker. But then you have to implement the caching
            // anyway.

            let ItemFromStorage = sessionStorage.getItem("Image"+encodeURIComponent(this.props.src))

            if (ItemFromStorage){
                console.log("Image", this.props.image,"taken from local Storage")
                this.setState({dataUrl:ItemFromStorage})
            }  else {
                let dataUrl = await requestImage({route: this.props.src})
                // console.log("Image", this.props.image,"fetched from Server, dataUrl", dataUrl)
                if (dataUrl && typeof dataUrl == "string") {
                try {
                    sessionStorage.setItem("Image"+encodeURIComponent(this.props.src), dataUrl)  
                } catch (err) {
                    // sometimes it exeeds a quota. 
                }
                  this.setState({dataUrl})
                } else {
                    console.error("Image dataUrl ist komisch", dataUrl)
                }
            }
        }
    }

    render() {
        
        if (this.state.dataUrl) {
            return (<ImageElement src={this.state.dataUrl}>
                </ImageElement>)
        }

        return "";
    }
}

export default Image