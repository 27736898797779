import React from 'react';
import styled from 'styled-components'

const BoxStyled = styled.div`
    font-size: 16px;
    padding: 10px 12px 20px;
    line-height: 22px;
    background: rgba(255,255,255,0.7);
    border: solid 1px var(--color-border);
    
    width: 100%
    
    @media(min-width: 500px) {
        padding: 20px;
    }
    @media(min-width: 900px) {
        border-radius: 5px;
        max-width: 860px;
        padding: 20px 40px 30px;
        margin: auto;
    }

    & > :first-child {
        margin-top: 0;
    }
`

const BoxInner = styled.div`
    width: auto;
`

class Box extends React.Component {
    render() {
        return (
            <BoxStyled>
                <BoxInner>
                    {this.props.children}
                </BoxInner>
            </BoxStyled>
      );
    }
}

export default Box