export default function formatFileSize (size) {
    if (Number.isNaN(size)) {
        return NaN
    }

    if (size < 1024) {
        return size + " B"
    }

    if (size < 1024*1024) {
        return size/1024 + " KB"
    }

    if (size < 1024*1024*1024) {
        return parseInt(size/1024/102.4)/10 + " MB"
    }
    
    if (size < 1024*1024*1024*1024) {
        return parseInt(size/1024/1024/102.4)/10 + " GB"
    }
    
}