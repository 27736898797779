import axios from "axios";
import { getAuthCode, getHost } from "./routeLogin/auth";

export default async function requestFile(conf) {

  if (!conf?.route) {
    console.warn("RequestFile got called without route in conf", conf)
  }  

  let response
  try {

    response = await axios({
      method: "get",
      url: getHost() + conf.route,
      responseType: "arraybuffer",
      headers:{
        Authorization: getAuthCode(),
      }
    });
  }
  catch (error) {
      console.error("errr", error)
      return { error };
  }
  
  if (response.data) {

    let baseEncodetData = arrayBufferToBase64(response.data)
    let type = response.headers["content-type"]
    // console.log("conf.route",conf.route,"baseEncodetData", baseEncodetData)
    
    let dataUrl = `data:${type};base64,${baseEncodetData}`;

    // console.log("requestImage dataUrl length", dataUrl.length)
    if (baseEncodetData) {
      return dataUrl
    }
    return false;
  } 
  else {
    console.error("RequestImage has no response.data but", response)
  }
  
  function arrayBufferToBase64 (buffer) {
    try {
      // liefert einen base64 artigen String zurück, aber nur ein paar Byte
      // baseEncodetData = window.btoa(response.data)
      
      // geht nur bei kleinen Bildern
      // baseEncodetData = window.btoa(String.fromCharCode(...new Uint8Array(response.data)));

      // requestImage error while btoa DOMException: String contains an invalid character
      // baseEncodetData = btoa(new TextDecoder('utf-8').decode(new Uint8Array(response.data)))

      // und dann soll das hier eine passende Konversion sein. Hässlich aber funktioniert
      let binary = '';
      let bytes = new Uint8Array(buffer);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
      
    } catch (err) {
      console.error("error while arrayBufferToBase64", err)
    }
  }
}