import React from 'react';
import styled from 'styled-components';
import ButtonRow from '../base/ButtonRow';
import Button from '../base/Button';
import request from '../RequestToHost';
import Error from "../base/Error"
import FieldDropdown from "../base/FieldDropdown"

const Spacer = styled.div`
    flex-grow: 10;
`

class BookMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        }

        this.handleDel = this.handleDel.bind(this)
        this.handleSortChange = this.handleSortChange.bind(this)
    }

    handleDel () {
        console.log("handleDel", this.props.bookKey)
        request({method: "delete", route: "/books/"+this.props.bookKey})
            .then((response) => { 
                if (response && response.error){
                    console.error(response.error)
                    this.setState({error: response.error.toString()})
                } else {
                        this.props.navigate("/")
                }           
            })
    }

    handleSortChange (e) {
        // console.log("handleSortChange e",e)
        this.props?.onSortChange(e)
    }

    render() {     
        const error = this.state.error ? <Error>{this.state.error}</Error> : ""
        let options = ["lastModified","created","title"]
        // console.log("bookMenu sortDefault", this.props.sortDefault)
        return (
            <div>
                {error}
                <ButtonRow>
                    <Spacer></Spacer>
                    <FieldDropdown default={this.props.sortDefault} options={options} onChange={this.handleSortChange}></FieldDropdown>
                    <Button small="small" layout="delete" action={this.handleDel}>del</Button>
                </ButtonRow>
            </div>
        )
    }
}

export default BookMenu