function pad(str) {
    let ret = str.toString();
    if(ret.length === 1) {
        ret = "0"+ret
     }
    return ret
}

function compareDates(date1, date2) {
    const compareYear = (date1.getFullYear() === date2.getFullYear())
    const compareMonth = (date1.getMonth() === date2.getMonth())
    const compareDate = (date1.getDate() === date2.getDate())

    return (compareYear && compareMonth && compareDate)
}

function isToday(date) {
    const today = new Date();

    return compareDates(today, date)
}

function isYesterday(date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1)

    return compareDates(yesterday, date)
}

function isLongAgo(date) {
    // BuggyBuggyBuggy
    const longAgo = new Date();
    // bug getDate returns the date of the month. this is no date math
    longAgo.setDate(longAgo.getDate() - 7)
    return compareDates(longAgo, date)
}

export default function formatDate (dateStr, prefix) {
    const date = new Date(parseInt(dateStr))

    let dateFormated = "am "+ pad(date.getDate())+"."+pad(date.getMonth()+1)+"."+date.getFullYear()+" "+pad(date.getHours())+":"+pad(date.getMinutes())    
    
    if (isLongAgo(date)) {
        dateFormated = "am "+ pad(date.getDate())+"."+pad(date.getMonth()+1)+"."+date.getFullYear()
    }

    if (isToday(date)) {
        dateFormated = "heute "+pad(date.getHours())+":"+pad(date.getMinutes())
    }
    if (isYesterday(date)) {
        dateFormated = "gestern "+pad(date.getHours())+":"+pad(date.getMinutes())
    }

    if (prefix) {
        dateFormated = prefix + dateFormated
    }
    
    return dateFormated
}