import React from "react";
import PageFront from "./routeFront/PageFront.jsx";
import Footer from "./base/Footer.jsx";
import PageContent from "./base/PageContent.jsx";
import PageBook from "./routeBook/PageBook.jsx";
import PageChapter from "./routeChapter/PageChapter.jsx";
import PageLogin from "./routeLogin/PageLogin.jsx";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {

  return (
    <Router>
      <div>
        <PageContent>
            <Routes>
              <Route path="/book/:bookKey" element={<PageBook />}></Route>
              <Route path="/book/:bookKey/:chapterKey" element={<PageChapter />}></Route>
              <Route path="/login" element={<PageLogin />}></Route>
              <Route path="/" element={<PageFront />}></Route>              
            </Routes>
          </PageContent>
          <Footer/>
      </div>

      
    </Router>
  );
}

// function RequireAuth({ children }: { children: JSX.Element }) {
//   let auth = useAuth();
//   let location = useLocation();

//   if (!auth.user) {
//     // Redirect them to the /login page, but save the current location they were
//     // trying to go to when they were redirected. This allows us to send them
//     // along to that page after they login, which is a nicer user experience
//     // than dropping them off on the home page.
//     
//   }

export default App;
