import React from 'react';
import styled from 'styled-components'
import { NavLink } from 'react-router-dom';
import Name from "../routeLogin/Name"


const Wrapper = styled.div`
    padding: 0px 0 5px;
    border-bottom: solid 1px var(--color-border);
    margin-bottom: 5px;
`

const Spacer = styled.div`
    flex-grow: 1;
`
const NavMenu = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    
    a {
        padding: 10 20px; 
        display: inline-block;
    }
`

function Header(props) {

    let links = []
    if (props.breadcrumb) {
        for (let item of props.breadcrumb) {
            links.push(<div key={item.key}>> <NavLink end to={item.link}>{item.title}</NavLink></div>)
        }
    }

    return (
    <Wrapper>
        <NavMenu>
            <NavLink end to='/'>Notus Bücher</NavLink>
            {links}
            <Spacer></Spacer>
            <NavLink to='/login'><Name/></NavLink>
        </NavMenu>
        
    </Wrapper>
    );

}

export default Header;
