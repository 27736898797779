import React from 'react'

import Textarea from '../base/Textarea'
import MetaFlex from './MetaFlex'

class BlockH3 extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleDel = this.handleDel.bind(this)
  }

  handleDel () {
    this?.props?.onDelete({id:this.props.id})
  }

  handleKeyDown (e) {
    // save on ctrl+s
    if (e.key === "s" && e.ctrlKey) {
      e.preventDefault()
      e.stopPropagation()
      this.saveContent(e.target.value)
    }

    if (e.key === "Backspace" && e.target.value === "") {
      // delete on backspace on an empty textarea
      this?.props?.onDelete({id:this.props.id})
    }
  }

  handleBlur (e) {
    console.log("BlockH3 handleBlur e", e)
    if (this.props?.block?.content !== e.target.value) {
      this.saveContent(e.target.value)
    }
  }

  
  saveContent (content) {
    this?.props?.onSaveContent(content)  
  }

  render () {
   
    let defaultContent = this.props?.block?.content || ""

    return (
    <div>
      <MetaFlex id={this.props.id} lastModified={this.props?.block?.lastModified} onDelete={this.handleDel}  saving={this.props.saving}></MetaFlex>
      <Textarea placeholder="H3" defaultContent={defaultContent} onBlur={this.handleBlur} onKeyDown={this.handleKeyDown} type="h3"></Textarea>
    </div>)
  }
}

export default BlockH3