import React from 'react';
import styled from 'styled-components'

const Element = styled.h1`
  // padding: 0 20px;
  font-weight: normal;

  font-size: 36px;
  line-height: 42px;
`

class H1 extends React.Component {
    render() {
        return (
            <Element>
                {this.props.children}
            </Element>
      );
    }
}

export default H1