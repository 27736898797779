import React from 'react';
import styled from 'styled-components';
import H2 from '../base/H2';
import Meta from "../base/Meta"
import formatDate from "../base/formatDate"
import Button from "../base/Button"
import ButtonRow from "../base/ButtonRow"
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
    background: #e8e8e8;
    padding: 10px 10px;
    border-radius: 3px;
`
const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
`

class BookButton extends React.Component {
    
    render() {     
        const lastModified = formatDate(this.props.abstract?.lastModified)
        const linkTo = "/book/"+this.props.abstract?.key
        const title = this.props.abstract?.title || "nameless"

        return (
            <Wrapper>
                <H2>{title}</H2>
                <Flex>
                    <Meta title="letzte Aktualisierung">{lastModified}</Meta>
                    <Meta>{this.props.abstract?.key}</Meta>
                </Flex>
                <ButtonRow>
                <NavLink end to={linkTo}>
                    <Button>anzeigen</Button>
                </NavLink>
                </ButtonRow>
            </Wrapper>     
        )
    }
}

export default BookButton