import React from 'react';
import { useNavigate} from "react-router-dom";
import { hasCredentials } from "../routeLogin/auth.js";
import GuestHint from "./GuestHint"
import Books from "./Books"

export default function PageFront() {
    let navigate = useNavigate();
    if (hasCredentials()) {
        return (
            <Books navigate={navigate}></Books>
        )    
    } else {
        return (
            <div>
                <GuestHint></GuestHint>
            </div>
        )
    }
}