import React from 'react'

import styled from 'styled-components'
import formatDate from '../base/formatDate'
import Meta from "../base/Meta"
import Button from "../base/Button"

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: center;
  gap: 10px;
  margin-bottom: 4px;
  
  Button {
    opacity: 0.3;
    transition: linear 200ms opacity;
  }
  &:hover Button {
    opacity: 1;
  }
`

const Spacer = styled.div`
  flex-grow: 100;
`

class MetaFlex extends React.Component {
  constructor(props) {
    super(props);
    this.handleDel = this.handleDel.bind(this)
  }


  handleDel (e) {
    this?.props?.onDelete({id:this.props.id})
  }

  

  render () {
   
    let savingSpinner = this.props.saving ? <div>saving</div> : ""
    let lastModified = formatDate(this.props.lastModified)

    return (
      <Meta><Flex><span title={this.props.id}>{this.props.type}</span><Spacer></Spacer> {savingSpinner} <div>{lastModified}</div> <Button layout="delete" small="small" action={this.handleDel}>del</Button></Flex></Meta>
    )
  }
}

export default MetaFlex