import React from 'react';
import request from '../RequestToHost';
import BlockText from './BlockText';
import BlockImage from './BlockImage';
import BlockFile from './BlockFile';
import BlockH2 from './BlockH2';
import BlockH3 from './BlockH3';
import BlockQuote from './BlockQuote';

class BlockChooser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: true, 
        error: false,
        block: {},
        saving: false
    };

    this.handleSave = this.handleSave.bind(this) // legacy
    this.handleSaveContent = this.handleSaveContent.bind(this) // the new way
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount() {
    if (this.props.blockKey) {
      this.requestBlock()
    }
  }

  handleSave({lastModified}) {
    console.log("blockChooser lastModified", lastModified)
    this?.props?.onSave({lastModified})
  }

  handleSaveContent (content) {
    let block = this.state.block
    block.content = content
    this.setState({saving: true, block: block})

    request({method: "post", route: "/blocks/"+this.props.blockKey, data: block})
    .then((response) => {
      console.log("blockchooser save post response", response)
      let block = this.state.block
      if (response?.data?.lastModified) {
        block.lastModified = response.data.lastModified
      }

      this.setState({saving: false, block})
      this?.props?.onSave({lastModified: block.lastModified})
    })
  }

  handleDelete({id}) {
    request({method: "delete", route: "/blocks/"+id})
    .then((response) => {
      this?.props?.onDelete({id})
    })
  }

  requestBlock () {
    this.setState({loading: true})

    request({route: "/blocks/"+this.props.blockKey})
    .then((response) => {
        // console.log("fetch then block response ", response)
        if (response.error) {
            this.setState({ error:response.error, loading: false })    
        }
        if (response.data) {
            this.setState({block: response.data, loading: false})
        }
    })
    .catch((error) => {
        this.setState({ error, loading: false })
    })

  }

  render () {
    let conditional = ""
    if (this.state.block?.type === "text") {
      conditional = (<BlockText id={this.state.block.id} saving={this.state.saving} block={this.state.block} onSaveContent={this.handleSaveContent} onDelete={this.handleDelete}></BlockText>)
    }
    if (this.state.block?.type === "h2") {
      conditional = (<BlockH2 id={this.state.block.id} saving={this.state.saving} block={this.state.block} onSaveContent={this.handleSaveContent} onDelete={this.handleDelete}></BlockH2>)
    }
    if (this.state.block?.type === "h3") {
      conditional = (<BlockH3 id={this.state.block.id} saving={this.state.saving} block={this.state.block} onSaveContent={this.handleSaveContent} onDelete={this.handleDelete}></BlockH3>)
    }
    if (this.state.block?.type === "quote") {
      conditional = (<BlockQuote id={this.state.block.id} saving={this.state.saving} block={this.state.block} onSaveContent={this.handleSaveContent} onDelete={this.handleDelete}></BlockQuote>)
    }
    if (this.state.block?.type === "image") {
      conditional =  (<BlockImage id={this.state.block.id} saving={this.state.saving} block={this.state.block} onSaveContent={this.handleSaveContent} onDelete={this.handleDelete}></BlockImage>)
    }
    if (this.state.block?.type === "file") {
      conditional =  (<BlockFile id={this.state.block.id} saving={this.state.saving} block={this.state.block} onSaveContent={this.handleSaveContent} onDelete={this.handleDelete}></BlockFile>)
    }

    return (<div>{conditional}</div>)
  }
}

export default BlockChooser