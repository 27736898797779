import React from 'react';

import request from '../RequestToHost';
import Meta from "../base/Meta"
import Box from "../base/Box"

import styled from 'styled-components';
import formatDate from '../base/formatDate';
import Error from "../base/Error"
import BlockChooser from './BlockChooser';
import BlockNew from './BlockNew';
import ChapterMenu from './ChapterMenu';
import Header from "../base/Header"

const Title = styled.input`
  background: transparent;
  font-size: 22px;
  line-height: 26px;
  padding: 5px 0;
  box-sizing: border-box;
  width: 100%;

  @media (min-width: 500px) {
    font-size: 32px;
    line-height: 38px;
    padding: 10px 0;     
  }

  border: none;
  transition: linear all 200ms;
  border-radius: 3px;

  &:hover {
    border: none;
    box-shadow: none;
  }

  &:focus {
    border: none;
    outline: none;
  }
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Spacer = styled.div`
  flex-grow: 10;
`

class Chapter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: true, 
        error: false,
        chapter: [],
        book: false,   
        changed: false,
        changedContent: "",
        changedTitle: ""
    };

    this.handleSave = this.handleSave.bind(this)
    this.handleBlockSave = this.handleBlockSave.bind(this)
    this.handleBlockDelete = this.handleBlockDelete.bind(this)
    this.handleTitleBlur = this.handleTitleBlur.bind(this)
    this.handleAddBlock = this.handleAddBlock.bind(this)
  }

  componentDidMount() {
    this.requestBook()
  }

  requestBook () {
    request({route: "/books/"+this.props.bookKey})
    .then((response) => {
        if (response.error) {
            this.setState({ error:response.error, loading: false })    
        }
        if (response.data) {
            this.setState({book: response.data, loading: false})
        }
    })
    .catch((error) => {
        this.setState({ error, loading: false })
    })
  }

  handleTitleBlur(e) {
    let book = this.state.book
    book.chapters[this.props.chapterKey].title = e.target.value
    this.save(book)
  }

  handleSave() {
    if (this.state.changed && this.state.chapter) {
      let chapter = this.state.chapter

      if (this.state.changedContent) {
        chapter.content = this.state.changedContent
      }
      if (this.state.changedTitle) {
        chapter.title = this.state.changedTitle
      }

      this.save({chapter})
    }    
  }

  handleBlockSave({lastModified}) {
    let book = this.state.book
    book.abstract.lastModified = lastModified
    book.chapters[this.props.chapterKey].lastModified = lastModified
    this.save(book)   
  }

  save(book) {
    this.setState({book, saving: true})

    request({method: "post", route: "/books/"+this.props.bookKey, data: book})
    .then((response) => {
      console.log("chapter save book post response ", response)
      if (response.data) {
        let book = this.state.book
        book.abstract.lastModified = response.data.lastModified
        book.chapters[this.props.chapterKey].lastModified = response.data.lastModified

        this.setState({ saving: false, book })    
      }
      if (response.error) {
        this.setState({ error:response.error, saving: false })    
      }
    })
  }

  handleBlockDelete({id}) {
    let book = this.state.book
    
    var filtered = book.chapters[this.props.chapterKey].blocks.filter(function(value, index, arr){ 
        return value !== id
    });
    book.chapters[this.props.chapterKey].blocks = filtered
    // console.log("chapter delete block id",id, "blocks", filtered)

    this.save(book)
  }

  handleAddBlock(e) {
    let book = this.state.book

    if (!book.chapters[this.props.chapterKey].blocks) {
      book.chapters[this.props.chapterKey].blocks = []
    }
    book.chapters[this.props.chapterKey].blocks.push(e)
    this.setState({book})

    request({method: "post", route: "/books/"+this.props.bookKey, data: book})
      .then((response) => {})
  }

  render () {
    if (!this.state.book) {
      return ("")
    }

    const lastModified = formatDate(this.state?.book.chapters[this.props.chapterKey]?.lastModified)

    let error = ""
    if (this.state.error) {
      const errorMessage = this.state.error.toString()
      error = <Error>{errorMessage}</Error>
    }

    let blocks = []
    if (this.state?.book.chapters[this.props.chapterKey]?.blocks) {
      for (let blockKey of this.state?.book.chapters[this.props.chapterKey]?.blocks) {
        if (typeof blockKey == "string") {
          blocks.push(<BlockChooser key={blockKey} blockKey={blockKey} onSave={this.handleBlockSave} onDelete={this.handleBlockDelete}></BlockChooser>)
        }
        else {
          console.log("chapter", this.props.chapterKey, "in book", this.props.bookKey,"has a malformed blockKey", blockKey)
        }
      }
    }

    let bookLink = "/book/"+this.props.bookKey
    let breadcrumb = [{link: bookLink, key: this.props.bookKey, title: this.state.book.abstract.title}]
    

    let conditional = ""
    if (this.state?.book.chapters[this.props.chapterKey]?.lastModified) {
      conditional = <div>
        <Header breadcrumb={breadcrumb}></Header>

        <Flex>
          <Meta> {this.props.bookKey} - last modified: {lastModified}
          </Meta>
          <Spacer></Spacer>
          <ChapterMenu bookKey={this.props.bookKey} chapterKey={this.props.chapterKey} book={this.state.book} navigate={this.props.navigate}></ChapterMenu>
        </Flex>
        
        {error}
          <Title type="text" defaultValue={this.state?.book.chapters[this.props.chapterKey]?.title} onBlur={this.handleTitleBlur} placeholder="chapter title"></Title>
        {blocks}
        <BlockNew addBlock={this.handleAddBlock}></BlockNew>
      </div>
    }

    return (
    <Box>
      {conditional}
    </Box>)
  }
}

export default Chapter