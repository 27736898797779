import React from 'react';
import styled from 'styled-components';
import ButtonRow from '../base/ButtonRow';
import Button from '../base/Button';
import request from '../RequestToHost';
import Error from "../base/Error"


const Wrapper = styled.div`

`

class ChapterMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        }

        this.handleDel = this.handleDel.bind(this)
    }

    async handleDel () {

        // delete the blocks
        // delete the update the book
        // navigate to book

        if (this.props.book?.chapters[this.props.chapterKey]?.blocks.length > 0) {
            for (let block of this.props.book?.chapters[this.props.chapterKey]?.blocks) {
                await request({method: "delete", route: "/blocks/"+block})                    
            }
        }

        let book = this.props.book
        delete book?.chapters[this.props.chapterKey]

        await request({method: "post", route: "/books/"+this.props.bookKey, data: book})
        this.props.navigate("/book/"+this.props.bookKey)
    }


    render() {     
        const error = this.state.error ? <Error>{this.state.error}</Error> : ""
        return (
            <Wrapper>
                {error}
                <ButtonRow margin="no">
                    <Button layout="delete" small="small" action={this.handleDel}>del</Button>
                </ButtonRow>
            </Wrapper>
        )
    }
}

export default ChapterMenu