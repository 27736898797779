import React from 'react';
import styled from 'styled-components'

const Element = styled.div`
    font-size: 12px;
    line-height: 16px;
    opacity: 0.9;
`

class Meta extends React.Component {
    render() {
        return (
            <Element>
                {this.props.children}
            </Element>
      );
    }
}

export default Meta