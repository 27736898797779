import axios from "axios";
import { getAuthCode, getHost } from "./routeLogin/auth";

export default async function downloadFile(conf) {

  if (!conf?.route) {
    console.warn("RequestFile got called without route in conf", conf)
  }  

  let response
  try {

    response = await axios({
      method: "get",
      url: getHost() + conf.route,
      responseType: "arraybuffer",
      headers:{
        Authorization: getAuthCode(),
      }
    });
  }
  catch (error) {
      console.error("errr", error)
      return { error };
  }
  
  if (response.data) {
    let type = response.headers["content-type"]   
    let fileName = conf.fileName
    if (fileName == undefined) {
      fileName= conf.route.split("/").pop()
    }

    var responseBlob = new Blob([response.data], { type });
    const href = URL.createObjectURL(responseBlob);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } 
  else {
    console.error("RequestFile has no response.data but", response)
  }
  
}