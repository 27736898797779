import React from 'react';
import styled from 'styled-components'

const ButtonStyled = styled.button`
    border: solid 1px rgba(0,0,0,0.2);
    background: var(--color-interaction-1);
    color: #fff;
    transition: background-color linear 200ms;
    border-radius: 3px;
    cursor: pointer;


    padding: 8px 14px;
    text-align: left;
    font-size: 13px;  
    font-weight: bold;

    &:hover,
    &:focus {
        background: var(--color-interaction-2);
        color: #fff;
        outline: none;
        box-shadow: none;
    }  
    
    &.marginTop {
        margin-top: 15px;
    }

    &.-neutral {
        background: transparent;
        color: var(--color-text);
        transition: all 100ms linear;

        &:hover,
        &:focus {
            background: var(--color-interaction-1);
            color: #fff;
        }
    }
    &.-delete {
        background: transparent;
        color: rgb(167,0,0);

        &:hover,
        &:focus {
            background: rgb(167,0,0);
            color: #fff;
        }
    }

    &.-block {
        width: 100%;
    }

    &.-disabled {
        background: transparent;
        color: var(--color-text-disabled);
        cursor: default;
    }

    &.-small {
        padding: 4px 6px;
        font-size: 11px;
    }

`

class Button extends React.Component {
    render() {
        let classString = []
        if (this.props.marginTop) {
            classString.push("marginTop")
        }

        if (this.props.layout === "neutral") {
            classString.push("-neutral")
        }

        if (this.props.layout === "delete") {
            classString.push("-delete")
        }

        if (this.props.block === "block") {
            classString.push("-block")
        }

        if (this.props.small === "small") {
            classString.push("-small")
        }

        if (this.props.disabled) {
            classString.push("-disabled")
        }

        return (
            <ButtonStyled disabled={this.props.disabled} onClick={this.props.action} className={classString} type={this.props.type}>
                {this.props.children}
            </ButtonStyled>            
      );
    }
}

export default Button