import React from 'react';
import Box from "../base/Box"

class PageFront extends React.Component {
    render() {
        
        return (
            <Box>
              Login. Nur für Mitglieder
            </Box>
        )
    }
}

export default PageFront