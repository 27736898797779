import React from 'react'
import request from '../RequestToHost'
import downloadFile from '../downloadFile.js'

import styled from 'styled-components'
import MetaFlex from './MetaFlex'
import Meta from "../base/Meta"
import Button from '../base/Button'
import formatFileSize from "../base/formatFileSize"

const Wrapper = styled.div`
  margin-bottom: 30px;
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`

const Input = styled.input`
  display: block;
  margin-bottom: 10px;
`

class BlockFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      savingFile: false,
      value: "",
      fileResult: ""
    }

    this.handleDel = this.handleDel.bind(this)
    this.handleFileInput = this.handleFileInput.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
  }

  handleDel () {
      this?.props?.onDelete({id:this.props.id})
  }

  handleFileInput(e) {
    this.setState({value: e.target.value});
    const file = e.target.files[0];
    this.setState({savingImage: true})

    const formData = new FormData();
		formData.append('file', file);

    request({method: "post", route: "/blockFile", data: formData, type: "multipart"})
    .then((response) => {
      this.setState({savingFile: false})

      let content = this.props?.block?.content || {}
      content.path = response.data.path
      content.fileName = file.name
      content.fileSize = file.size
      content.fileType = file.type
      this.saveContent(content)
    })
  }

  
  async handleDownload () {
    await downloadFile({route: this.props?.block?.content?.path, fileName: this.props?.block?.content?.fileName})
  }

  saveContent (content) {
    this?.props?.onSaveContent(content)  
  }

  render () {
    let conditional = ""
    if (this.props.block?.content?.path) {
      const sizeString = formatFileSize(this.props.block?.content?.fileSize)

      conditional = <Flex>
        <div>
          <div>{this.props.block?.content?.fileName}</div>
          <Meta>
          {sizeString}, {this.props.block?.content?.fileType}
          </Meta>
        </div>
        <div>
          <Button action={this.handleDownload}>download</Button>
        </div>  
          
      </Flex>
    } else {
      conditional = <div>
        <Input 
          type="file" 
          value={this.state.value} 
          onInput={this.handleFileInput}
          >
        </Input>
      </div>
    }

    let isSaving = this.props.saving || this.state.savingImage

    return (
    <Wrapper>
      <MetaFlex id={this.props.id} lastModified={this.props?.block?.lastModified} onDelete={this.handleDel} saving={isSaving}></MetaFlex>
      {conditional}
    </Wrapper>)
  }
}

export default BlockFile