import React from 'react';
import styled from 'styled-components';

const Fieldgroup = styled.div`
`

const Input = styled.input``
const Label = styled.label``

class FieldDropdownOption extends React.Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.state = {value: this.props.value || ""};
    }

    handleChange (){
        if (this.props.onchange) {
            this.props.onchange(this.props.value)
        }
    }

    render() {
        let id = "id-"+parseInt(Math.random()*1000000000)

        return (
        <Fieldgroup>
            <Input id={id} type="radio" name={this.props.name} value={this.props.value} onInput={this.handleChange} defaultChecked={this.props.defaultChecked} />
            <Label htmlFor={id}>{this.props.label}</Label>
        </Fieldgroup>
      );
    }
}

export default FieldDropdownOption