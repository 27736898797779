import React from 'react';
import FieldDropdownOption from './FieldDropdownOption';

class FieldDropdown extends React.Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.state = {value: this.props.value || ""};

        this.handleChange(this.props?.default)
    }
    
    handleChange (e) {
        if (this.props.onChange) {
            this.props.onChange(e)
        }
    }

    render() {
        let Inputs = []
        for (let option of this.props.options) {
            let checked = (this.props.default === option)

    
            Inputs.push(<FieldDropdownOption 
                key={option} 
                value={option} 
                name="orderType" 
                label={option} 
                defaultChecked={checked}
                onchange={this.handleChange}>
            </FieldDropdownOption>)
        }

        return (
        <div>
            {Inputs}
        </div>
      );
    }
}

export default FieldDropdown