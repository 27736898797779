/* eslint-disable no-unused-vars */
import React from 'react';
import request from '../RequestToHost';
import randomId from "../base/randomId"
import Meta from "../base/Meta"
import formatDate from "../base/formatDate"
import Box from "../base/Box"
import ButtonRow from '../base/ButtonRow';
import Button from '../base/Button';
import ChapterButton from './ChapterButton';
import Header from "../base/Header"

import styled from 'styled-components';
import BookMenu from './BookMenu';

const Flex = styled.div`
  display: flex;
  gap: 20px;
  vertical-align: center;
  flex-direction: row-reverse;
`

const Title = styled.input`
  background: transparent;
  font-size: 22px;
  line-height: 26px;
  padding: 5px 0;
  box-sizing: border-box;
  width: 100%;

  @media (min-width: 500px) {
    font-size: 32px;
    line-height: 38px;
    padding: 20px 0 10px; 
  }

  border: none;
  transition: linear all 200ms;
  border-radius: 3px;

  &:hover {
    border: none;
    box-shadow: none;
  }

  &:focus {
    border: none;
    outline: none;
  }
`

let List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;  
`

class Book extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: false, 
        error: false,
        book: [],
        saving: false
    };

    this.handleSortChange = this.handleSortChange.bind(this)
    this.handleCreateChapter = this.handleCreateChapter.bind(this)
    this.handleTitleBlur = this.handleTitleBlur.bind(this)
  }

  componentDidMount() {
    this.requestBook()
  }

  requestBook () {
    request({route: "/books/"+this.props.bookKey})
    .then((response) => {
        // console.log("fetch then book response ", response)
        if (response.error) {
            this.setState({ error:response.error, loading: false })    
        }
        if (response.data) {
            this.setState({book: response.data, loading: false})
        }
    })
    .catch((error) => {
        this.setState({ error, loading: false })
    })
  }

  handleCreateChapter () {
    // console.log("handleCreateChapter state", this.state)
    let book = this.state.book
    let chapterId = randomId()
    book.chapters[chapterId] ={
      title: "",
      lastModified: Date.now(),
      created: Date.now(),
      id: chapterId,
    }
    
    request({method: "post", route: "/books/"+this.props.bookKey, data: book})
    .then((response) => {
        // console.log("fetch then book response ", response)
        if (response.error) {
            this.setState({ error:response.error, loading: false })    
        }
        if (response.data) {
            this.props.navigate("/book/"+this.props.bookKey+"/"+chapterId)
        }
    })
  }

  handleTitleBlur (e) {
    let book = this.state.book
    if (book.abstract.title !== e.target.value) {
      book.abstract.title = e.target.value
      this.saveBook(book)
    }
  }

  saveBook (book) {
    // console.log("saveBook", book)
    this.setState({saving: true})

    request({method: "post", route: "/books/"+this.props.bookKey, data: book})
    .then((response) => {
        if (response.error) {
          this.setState({ error:response.error, saving: false })    
        } else {
          this.setState({saving: false})
          this.requestBook()
        }
    })
  }

  handleSortChange (e) {
    let book = this.state.book
    book.abstract.sortOrder = e
    this.saveBook(book)
  }

  sortByLastModified (chapters) {
    let keys = Object.keys(chapters)
    keys.sort((a,b) => {
      return chapters[b].lastModified - chapters[a].lastModified
    })
    return keys
  }

  sortByCreated (chapters) {
    let keys = Object.keys(chapters)
    keys.sort((a,b) => {
      return chapters[a].created - chapters[b].created
    })
    return keys
  }

  sortByTitle (chapters) {
    let keys = Object.keys(chapters)
    keys.sort((a,b) => {
      let aTitle = chapters[a].title.toUpperCase()
      let bTitle = chapters[b].title.toUpperCase()
      if (aTitle < bTitle) { return -1 }
      if (aTitle > bTitle) { return 1 }
      return 0
    })
    return keys
  }

  render () {
    // console.log("book render state", this.state )
    const lastModified = formatDate(this.state?.book?.abstract?.lastModified)
    let chapterArrayTag = []
    let chapters = this.state?.book?.chapters
    if (!chapters) {
      return ""
    }

    let sortedKeys
    // console.log("render sort by", this.state?.book?.abstract.sortOrder)

    switch (this.state?.book?.abstract?.sortOrder || "lastModified") {
      case "lastModified": sortedKeys = this.sortByLastModified(chapters); break
      case "created": sortedKeys = this.sortByCreated(chapters); break
      case "title": sortedKeys = this.sortByTitle(chapters); break
      default: break
    }
    // let 
    // let sortedKeys = this.sortByCreated(chapters)
    // sortedKeys = this.sortByTitle(chapters)

    for (let key of sortedKeys){
      let chapter = chapters[key]
      if (chapter) {
        chapterArrayTag.push(<ChapterButton key={key} bookKey={this.state.book.abstract.key} chapterKey={chapter.id} title={chapter.title} lastModified={chapter.lastModified}></ChapterButton>)
      }
    }

    let title = this.state.book?.abstract?.title ||""   
    return (
    <Box>
      <Header></Header>
      <Meta> {this.state?.book?.abstract?.key} - last modified: {lastModified}</Meta>
      <Flex>
        <BookMenu bookKey={this.props.bookKey} navigate={this.props.navigate} onCreateChapter={this.handleCreateChapter} sortDefault={this.state.book.abstract.sortOrder} onSortChange={this.handleSortChange}></BookMenu>
        <Title defaultValue={title} placeholder="Buchtitel" onBlur={this.handleTitleBlur}></Title>
      </Flex>
      
      <List>
        {chapterArrayTag}
      </List>
      
      <ButtonRow>
        <Button action={this.handleCreateChapter}>neues Kapitel hinzufügen</Button>
      </ButtonRow>

    </Box>)
  }
}

export default Book