import React from 'react';
import styled from 'styled-components'

const Element = styled.h2`
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 22px;
    line-height= 28px;
`

class H2 extends React.Component {
    render() {
        return (
            <Element>
                {this.props.children}
            </Element>
      );
    }
}

export default H2