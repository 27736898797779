import React from 'react'
import styled from 'styled-components'

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  &:hover :placeholder {
    color: red;
  }
`

const HeightKeeper = styled.div`
  width: 1px;
  margin-right: -1px;  
`

const Content = styled.textarea`
  background: transparent;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  transition: linear border-color 200ms;
  padding: 0;
  resize: none;
  overflow: hidden;

  &.h2 {
    font-size: 24px;
    line-height: 32px;
  }

  &.h3 {
    font-size: 20px;
    line-height: 26px;
  }

  &.quoteText {
    text-align: center;
    font-size: 30px;
    line-height: 36px;
    font-style: italic;
  }

  &.quoteAuthor {
    color: #666;
    text-align: right;

    &:before {
      content: "- ";

    }
  }

  &:hover {
    border: none;
  }

  &:focus {
    border: none;
    outline: none;
  }
`

class Textarea extends React.Component {
  constructor(props) {
    super(props);

    this.refTextarea = React.createRef();
    this.refHeightKeeper = React.createRef();

    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  setTextareaHeight() {
    
    // must be. without that the textarea gets longer and longer
    this.refTextarea.current.style.height = "inherit"
    // this is the target height, some more for design
    this.refTextarea.current.style.height = this.refTextarea.current.scrollHeight+"px"
    // keep the height. without this the inherit clears the height and firefox scrolls
    // to the end of the new shorter body height
    // but with the height keeper the parent flex stays in the same height
    this.refHeightKeeper.current.style.height = this.refTextarea.current.scrollHeight+"px"
  }
  componentDidMount(){
    this.setTextareaHeight()
  }

  handleChange () {
    this.setTextareaHeight()
  }

  handleKeyDown (e) {
    if (this.props?.onKeyDown) {
      this.props.onKeyDown(e)
    }
  }

  handleBlur (e) {
    console.log("Textarea handleBlur ref", this.refTextarea.current.value)
    if (this.props?.onBlur) {
      this.props.onBlur(e)
    }
  }

  render () { 
    let defaultContent = this.props?.defaultContent || ""
    let className = ""
    if (this.props.type === "h2") {
      className += "h2"
    }
    if (this.props.type === "h3") {
      className += "h3"
    }
    
    if (this.props.type === "quoteText") {
      className += "quoteText"
    }
    
    if (this.props.type === "quoteAuthor") {
      className += "quoteAuthor"
    }

    let placeholder = this.props.placeholder || ""

    return (
      <ContentWrapper>
        <HeightKeeper ref={this.refHeightKeeper}></HeightKeeper>
        <Content 
          ref={this.refTextarea} 
          defaultValue={defaultContent} 
          onKeyDown={this.handleKeyDown} 
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          rows="1"
          className={className}
          placeholder={placeholder}></Content>
      </ContentWrapper>
    )
  }
}

export default Textarea